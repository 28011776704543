import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import { IsNullOrEmpty, MetinKisalt, SeoUrlRegex } from "../../libs/utils";
import Language from "../../locales/GetLanguageResourceResponse.json";
import Image from "next/image";
import DeliveryImg from "../../public/static/imgs/template/delivery.svg";
import SupportImg from "../../public/static/imgs/template/support.svg";
import VoucherImg from "../../public/static/imgs/template/voucher.svg";
import ReturnImg from "../../public/static/imgs/template/return.svg";
import SecureImg from "../../public/static/imgs/template/secure.svg";
import KapakImg from "../../public/milli-mucadelenin-yuzuncu-yili/kapak.jpg";
import "swiper/css";

const Slider = ({ data }) => {
  const [language, setLanguage] = useState("tr");
  const languageStore = useSelector((state) => state.language);

  return (
    <>
      <section className="section-box">
        <div className="banner-hero banner-homepage3">
          <div className="container-banner">
            <div className="box-swiper">
              <div style={{ position: "relative" }}>
                <ul id="buttons" className="custom-swiper-buttons">
                  <li className="custom-button" id="prevButton">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      width={20}
                      height={20}>
                      <path
                        fill="#ffffff"
                        d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                      />
                    </svg>
                  </li>
                  <li className="custom-button" id="nextButton">
                    <svg
                      width={20}
                      height={20}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512">
                      <path
                        fill="#ffffff"
                        d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                      />
                    </svg>
                  </li>
                </ul>

                {/* Swiper Component */}
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  pagination={{ clickable: true }}
                  navigation={{
                    prevEl: "#prevButton", // Custom previous button
                    nextEl: "#nextButton", // Custom next button
                  }}>
                  <SwiperSlide>
                    <Image
                      src={KapakImg}
                      title="Milli Mücadelenin 100. Yılı"
                      className="ptt-slider-img"
                      width={600}
                      height={400}
                    />
                    <div className="box-slide-bg-1">
                      <h1 className="font-58 mt-20">
                        Milli Mücadelenin 100. Yılı
                      </h1>
                      <div className="mt-10">
                        <ul className="list-disc">
                          <li className="font-lg">
                            {" "}
                            {Language[languageStore].guvenliOdemeTitle}
                          </li>

                          <li className="font-lg">
                            {" "}
                            {Language[languageStore].destekHattiTitle}
                          </li>
                        </ul>
                      </div>
                      <div className="mt-30">
                        <Link
                          className="btn btn-pttFilateli btn-md"
                          href={`/urunler/milli-mucadelenin-100-yili-koleksiyonu/645301b4acf2f15e497facf754b88532`}>
                          {Language[languageStore].anasayfaSliderBtnTitle}
                        </Link>
                      </div>
                      <div className="mt-5 mb-120">
                        <Link
                          className="btn btn-pttFilateli btn-md"
                          href="milli-mucadelenin-yuzuncu-yili">
                          {Language[languageStore].kataloguIncele}
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>

                  {data &&
                    data.map((slider, index) => (
                      <SwiperSlide key={index}>
                        <img
                          src={slider.resimLink}
                          title={slider.urunAd}
                          className="ptt-slider-img"
                          width={650}
                          height={400}
                          style={{ marginBottom: 40 }}
                        />
                        <div className="box-slide-bg-1">
                          <h2 className="font-28 mt-15">{slider.urunAd}</h2>
                          <div className="mt-10">
                            <ul className="list-disc">
                              <li className="font-lg">
                                {Language[languageStore].guvenliOdemeTitle}
                              </li>
                              <li className="font-lg">
                                {Language[languageStore].destekHattiTitle}
                              </li>
                            </ul>
                          </div>
                          <div className="mt-30 mb-120">
                            <a
                              className="btn btn-pttFilateli btn-md"
                              href={`/urunler/${SeoUrlRegex(slider.urunAd)}/${
                                slider.urunId
                              }`}>
                              {Language[languageStore].anasayfaSliderBtnTitle}
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-box mt-10">
        <div className="container">
          <ul className="list-col-5">
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={DeliveryImg} />
                </div>
                <div className="info-right">
                  <Link href="/kurumsal/emisyon-programi">
                    <h5 className="font-md-bold color-gray-900">
                      {Language[languageStore].emisyonProgramiTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {" "}
                      {Language[languageStore].emisyonProgramiSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={SupportImg} />
                </div>
                <div className="info-right">
                  <Link href="/kurumsal/bize-ulasin">
                    <h5 className="font-md-bold color-gray-900">
                      {" "}
                      {Language[languageStore].destekHattiTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {Language[languageStore].destekHattiSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={VoucherImg} />
                </div>
                <div className="info-right">
                  <Link
                    href="https://www.ptt.gov.tr/duyurular?page=1&announcementType=2"
                    target="_blank">
                    <h5 className="font-md-bold color-gray-900">
                      {" "}
                      {Language[languageStore].duyurularTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {" "}
                      {Language[languageStore].duyurularSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={ReturnImg} />
                </div>
                <div className="info-right">
                  <Link href="/kurumsal/iptal-ve-iade-kosullari">
                    <h5 className="font-md-bold color-gray-900">
                      {" "}
                      {Language[languageStore].urunIadesiTitle}
                    </h5>
                    <p className="font-sm color-gray-500">
                      {Language[languageStore].urunIadesiSlogan}
                    </p>
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <div className="item-list">
                <div className="icon-left">
                  <Image src={SecureImg} alt="Güvenli Ödeme" />
                </div>
                <div className="info-right">
                  <h5 className="font-md-bold color-gray-100">
                    {" "}
                    {Language[languageStore].guvenliOdemeTitle}
                  </h5>
                  <p className="font-sm color-gray-500">
                    {" "}
                    {Language[languageStore].guvenliOdemeSlogan}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Slider;
