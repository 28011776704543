import { useSelector } from "react-redux";
import Link from "next/link";
import Language from "../../locales/GetLanguageResourceResponse.json";
import { LanguageType } from "../../libs/enums";
import { useEffect } from "react";
const NewLetter = () => {
  const languageStore = useSelector((state) => state.language);

  return (
    <>
      {" "}
      <section className="section-box box-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 p-2">
              <ul className="list-brands">
                <li>
                  <div className="swiper-slide">
                    <a href="https://www.pttavm.com/" target="_blank">
                      <img src="/static/imgs/ptt-avm.png" alt="PTT-AVM" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="swiper-slide">
                    <a
                      href="https://www.ptt.gov.tr/para-transferleri-yurt-ici-havale-hizmeti"
                      target="_blank"
                    >
                      <img src="/static/imgs/ptt-bank.png" alt="PTT-BANKA" />
                    </a>
                  </div>
                </li>

                <li>
                  <div className="swiper-slide">
                    <a
                      href="https://hgsmusteri.ptt.gov.tr/hgs.jsf"
                      target="_blank"
                    >
                      <img src="/static/imgs/hgs.png" alt="HGS" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="swiper-slide">
                    <a
                      href="https://www.ptt.gov.tr/ptt-yurt-disi-kargo-hizmetleri-turpex"
                      target="_blank"
                    >
                      <img src="/static/imgs/turpex.png" alt="PTT-TURPEX" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="swiper-slide">
                    <a href="https://www.pttcell.com.tr/" target="_blank">
                      <img src="/static/imgs/ptt-cell.png" alt="PTT-CELL" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="swiper-slide">
                    <a
                      href="https://www.ptt.gov.tr/kargo-ptt-yurt-ici-kargo-hizmetleri"
                      target="_blank"
                    >
                      <img src="/static/imgs/ptt-kargo.png" alt="PTT-KARGO" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="swiper-slide">
                    <a
                      href="https://www.ptt.gov.tr/posta-kayitli-elektronik-posta"
                      target="_blank"
                    >
                      <img src="/static/imgs/ptt-kep.png" alt="PTT-kep" />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="swiper-slide">
                    <a href="https://www.pttsigorta.com/" target="_blank">
                      <img
                        src="/static/imgs/ptt-sigorta.png"
                        alt="PTT-sigorta"
                      />
                    </a>
                  </div>
                </li>

                <li>
                  <div className="swiper-slide">
                    <a
                      href="https://www.ptt.gov.tr/lojistik-hizmetlerimiz"
                      target="_blank"
                    >
                      <img
                        src="/static/imgs/ptt-lojistik.png"
                        alt="PTT-lojistik"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  <div className="swiper-slide">
                    <a href="https://akilliesnaf.ptt.gov.tr/" target="_blank">
                      <img
                        src="/static/imgs/ptt-akilli-esnaf.png"
                        alt="PTT-akilli-esnaf"
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewLetter;
