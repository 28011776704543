import { useSelector } from "react-redux";
import Link from "next/link";
import Language from "../../locales/GetLanguageResourceResponse.json";
import { LanguageType } from "../../libs/enums";
import Image from "next/image";
import pulImg from "../../public/static/imgs/template/pullar.jpg";
import zarflarImg from "../../public/static/imgs/template/zarflar.jpg";
import extraImg from "../../public/static/imgs/template/extra.jpg";
import kartlarImg from "../../public/static/imgs/template/kartlar.jpg";

const CategoryCard = () => {
  const languageStore = useSelector((state) => state.language);

  return (
    <div>
      <div className="section-box">
        <div className="container">
          <div className="row mt-30">
            <div className="pb-0 head-main text-center border-none">
              <h4 className="color-gray-900">
                {Language[languageStore].anasayfaPopulerKategorilerTitle}
              </h4>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card-grid-style-2 card-grid-style-2-small">
                <div className="image-box">
                  <Link href="#">
                    <Image src={pulImg} />
                  </Link>

                  <div className="mt-10 text-center">
                    <Link
                      className="btn btn-pttFilateli"
                      href={`/kategoriler/anma-bloku/1004/`}
                    >
                      {Language[languageStore].anasayfaHepsiniGoruntuleBtnTitle}
                    </Link>
                  </div>
                </div>
                <div className="info-right">
                  <a className="color-brand-3 font-sm-bold" href="#">
                    <h6>
                      {" "}
                      {languageStore === LanguageType.TR ? "Pullar" : "Stamps"}
                    </h6>
                  </a>
                  <ul className="list-links-disc">
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/anma-pulu/1001"
                            : "/kategoriler/commemorative-stamps/1001"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Anma Pulu"
                          : "Commemorative Stamps"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/surekli-pul/1002"
                            : "/kategoriler/definitive-stamps/1002"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Sürekli Pul"
                          : "Definitive Stamps"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/resmi-pul/1003"
                            : "/kategoriler/official-postage-stamps/1003"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Resmi Pul"
                          : "Official Postage Stamps"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card-grid-style-2 card-grid-style-2-small">
                <div className="image-box">
                  <Link href="#">
                    <Image src={zarflarImg} />
                  </Link>

                  <div className="mt-10 text-center">
                    <Link
                      className="btn btn-pttFilateli"
                      href={`/kategoriler/anma-bloku/1004/`}
                    >
                      {Language[languageStore].anasayfaHepsiniGoruntuleBtnTitle}
                    </Link>
                  </div>
                </div>
                <div className="info-right">
                  <a className="color-brand-3 font-sm-bold" href="#">
                    <h6>
                      {" "}
                      {languageStore === LanguageType.TR
                        ? "Zarflar"
                        : "Envelopes"}
                    </h6>
                  </a>
                  <ul className="list-links-disc">
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/anma-pulu-ilk-gun-zarfi/1101"
                            : "/kategoriler/commemorative-stamp-fdc/1101"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Anma Pulu İlk Gün Zarfı"
                          : "Commemorative Stamp FDC"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/surekli-pulu-ilk-gun-zarfi/1102"
                            : "/kategoriler/definitive-stamp-fdc/1102"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Sürekli Pul İlk Gün Zarfı"
                          : "Definitive Stamp FDC"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/ozel-gun-zarfi/1108"
                            : "/kategoriler/special-day-envelope/1108"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Özel Gün Zarfı"
                          : "Special Day Envelope"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card-grid-style-2 card-grid-style-2-small">
                <div className="image-box">
                  <Link href="#">
                    <Image src={kartlarImg} />
                  </Link>

                  <div className="mt-10 text-center">
                    <Link
                      className="btn btn-pttFilateli"
                      href={`/kategoriler/anma-bloku/1004/`}
                    >
                      {Language[languageStore].anasayfaHepsiniGoruntuleBtnTitle}
                    </Link>
                  </div>
                </div>
                <div className="info-right">
                  <a className="color-brand-3 font-sm-bold" href="#">
                    <h6>
                      {" "}
                      {languageStore === LanguageType.TR ? "Kartlar" : "Cards"}
                    </h6>
                  </a>
                  <ul className="list-links-disc">
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/tebrik-karti/1106"
                            : "/kategoriler/greeting-cards/1106"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Tebrik Kartı"
                          : "Greeting Cards"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/maksimum-kart/1107"
                            : "/kategoriler/maximum-cards/1107"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Maksimum Kart"
                          : "Maximum Cards"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/posta-kart/1110"
                            : "/kategoriler/post-cards/1110"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Posta Kart"
                          : "Post Cards"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="card-grid-style-2 card-grid-style-2-small">
                <div className="image-box">
                  <Link href="#">
                    <Image src={extraImg} />
                  </Link>

                  <div className="mt-10 text-center">
                    <Link
                      className="btn btn-pttFilateli"
                      href={`/kategoriler/anma-bloku/1004/`}
                    >
                      {Language[languageStore].anasayfaHepsiniGoruntuleBtnTitle}
                    </Link>
                  </div>
                </div>
                <div className="info-right">
                  <a className="color-brand-3 font-sm-bold" href="#">
                    <h6>
                      {" "}
                      {languageStore === LanguageType.TR
                        ? "Ekstra Ürünler"
                        : "Extra Products"}
                    </h6>
                  </a>
                  <ul className="list-links-disc">
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/portfoy/901"
                            : "/kategoriler/portfolio/901"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Portföy"
                          : "Portfolio"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/yillik-pul-poseti/1111"
                            : "/kategoriler/yearly-stamp-pack/1111"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Yıllık Pul Poşeti"
                          : "Yearly Stamp Pack"}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="font-sm"
                        href={
                          languageStore === LanguageType.TR
                            ? "/kategoriler/filatelik-aksesuarlar/1000"
                            : "/kategoriler/philatelic-accessories/1000"
                        }
                      >
                        {languageStore === LanguageType.TR
                          ? "Filatelik Aksesuarlar"
                          : "Philatelic Accessories"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
