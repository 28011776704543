import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Head from "next/head";
import { API_BASE_URL } from "../libs/generalSettings";
import { get } from "../libs/services/request";
import CategoryCard from "../components/CatagoryCard";
import NewLetter from "../components/NewLetter";
import Slider from "../components/Slider";
import ProductsCard from "../components/ProductsCard";
import Language from "../locales/GetLanguageResourceResponse.json";

export default function Home(props) {
  const [EnCokSatanlarLimitli, setEnCokSatanlarLimitli] = useState([]);
  const [language, setLanguage] = useState("tr");
  const languageStore = useSelector((state) => state.language);
  useEffect(() => {
    (async () => {
      await get(`${API_BASE_URL}/getSonEklenenler?dil=${languageStore}`).then(
        (result) => {
          {
            if (result.sonucAciklama === "Başarılı") {
              setLanguage(languageStore);
              setEnCokSatanlarLimitli(result.data);
            }
          }
        }
      );
    })();
  }, [languageStore]);

  return (
    <>
      <Head>
        <title>{Language[languageStore].webTitle}</title>
      </Head>

      <Slider data={EnCokSatanlarLimitli} />
      <CategoryCard />
      <ProductsCard
        data={EnCokSatanlarLimitli}
        title={Language[language].anasayfaEnCokSatanUrunlerTitle}
      />
      <NewLetter />
    </>
  );
}
