import Swal from "sweetalert2/dist/sweetalert2.js";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import { MetinKisalt, SeoUrlRegex } from "../../libs/utils";
import Language from "../../locales/GetLanguageResourceResponse.json";
import { incrementCartItemCount } from "../../redux/stores/basket";
import { encryptJson, decrypt } from "../../libs/encryption";
import { API_BASE_URL } from "../../libs/generalSettings";
import { postJSON } from "../../libs/services/request";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import Image from "next/image";
import star from "../../public/static/imgs/template/icons/star.svg";
const ProductsCard = ({ data, title }) => {
  const languageStore = useSelector((state) => state.language);
  const basket = useSelector((state) => state.basket);

  const isLoggin = decrypt(useSelector((state) => state.auth.loggedIn));
  const userId = decrypt(useSelector((state) => state.auth.user));
  const token = decrypt(useSelector((state) => state.auth.authorization));
  const cleanedToken = token.replace(/"/g, "");
  const router = useRouter();
  const dispatch = useDispatch();

  const sepeteEkle = async (product) => {
    if (isLoggin) {
      var uid = JSON.parse(userId);
      const params = {
        urunId: product.urunId,
        musteriId: uid.fltMusteriId,
        adet: 1,
        token: cleanedToken,
      };
      await postJSON(`${API_BASE_URL}/sepeteUrunEkle`, params).then(
        (result) => {
          {
            if (result.sonuc === true) {
              let timerInterval;
              Swal.fire({
                title: Language[languageStore].urunSepeteEklendi,
                html: "<b>" + product.urunAd + "</b>",
                icon: "success",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              dispatch(incrementCartItemCount());
            } else {
              Swal.fire({
                title: Language[languageStore].urunSepeteEklenmedi,
                html: "<b>" + product.urunAd + "</b>",
                icon: "error",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
            }
          }
        }
      );
    } else {
      router.push("/login");
    }
  };
  const container = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <>
      <section className="section-box mt-30">
        <div className="container">
          <div className="pb-0 head-main text-center border-none">
            <h4 className="color-gray-900">{title}</h4>
          </div>

          <div className="box-swiper">
            <motion.div
              variants={container}
              initial="hidden"
              animate="visible"
              className="list-products-5 list-style-brand-2"
            >
              {data && data.length > 0
                ? data.map((products, index) => {
                    return (
                      <div className="card-grid-style-3" key={index}>
                        <motion.div variants={item} className="card-grid-inner">
                          <div className="image-box">
                            <Link
                              href={`/urunler/${SeoUrlRegex(products.urunAd)}/${
                                products.urunId
                              }`}
                              title={products.urun_ad}
                            >
                              {" "}
                              <img
                                src={products.resimLink}
                                width={400}
                                height={400}
                                alt={products.urunAd}
                                title={products.urunAd}
                              />
                            </Link>
                          </div>
                          <div className="info-right">
                            <Link
                              className="font-xs color-gray-500"
                              href={`/urunler/${SeoUrlRegex(products.urunAd)}/${
                                products.urunId
                              }`}
                              title={products.urunAd}
                            >
                              {products.kategoriAdi}
                            </Link>
                            <br />
                            <Link
                              className="color-brand-3 font-sm-bold"
                              href={`/urunler/${SeoUrlRegex(products.urunAd)}/${
                                products.urunId
                              }`}
                              title={products.urunAd}
                            >
                              {" "}
                              <MetinKisalt
                                text={
                                  products.urunAd === null
                                    ? "null"
                                    : products.urunAd
                                }
                                maxLength={25}
                              />
                            </Link>

                            <div className="rating">
                              <div className="ptt-rating">
                                <Image src={star} alt={products.urunAd} />
                                <Image src={star} alt={products.urunAd} />
                                <Image src={star} alt={products.urunAd} />
                                <Image src={star} alt={products.urunAd} />
                                <Image src={star} alt={products.urunAd} />
                                <br />
                                <span className="font-xs color-gray-500">
                                  {Language[languageStore].stokAdetiTitle}: (
                                  {products.stokAdet})
                                </span>
                              </div>
                            </div>
                            <div className="price-info">
                              <strong className="font-lg-bold color-brand-3 price-main">
                                {products.ucret}&nbsp;TL
                              </strong>
                            </div>
                            <div className="mt-20 box-btn-cart">
                              <button
                                className="btn btn-cart"
                                onClick={() => sepeteEkle(products)}
                              >
                                {Language[languageStore].sepeteEkleBtnTitle}
                              </button>
                            </div>
                            <ul className="list-features">
                              <li>
                                {Language[languageStore].urunKoduTitle} :{" "}
                                {products.urunKod}
                              </li>
                              <li>
                                {Language[languageStore].baskiYontemiTitle} :
                                {products.baskiYontemi}
                              </li>
                              <li>
                                {Language[languageStore].basimEviTitle} :
                                {products.basimevi}
                              </li>
                            </ul>
                          </div>
                        </motion.div>
                      </div>
                    );
                  })
                : ""}
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsCard;
